<template>
  <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
  >
    <div
      v-if="!restricted"
      v-show="!loading"
      :key="elementKey"
    >
      <validation-observer ref="simpleRules">
        <b-form
          ref="request"
          @submit.prevent="submit"
        >

              <request-post-actions :data="pointData" :submitting="submitting" />

          <b-row class="match-height">
            <b-col
              cols="12"
              xl="8"
              class="order-sm-1"
            >
              <request-overview
                ref="overview"
                :data="pointData"
              />
            </b-col>
            <!--            <b-col-->
            <!--              lg="6"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <request-time-gaps-->
            <!--                :data="pointData"-->
            <!--              />-->
            <!--            </b-col>-->

            <b-col
              cols="12"
              xl="8"
              class="order-sm-2 order-md-3"
            >
              <set-location
                  ref="map"
                  :data="pointData"
                  :viewport="offerViewport"
                  @update-location="val=>updateLocationEvent(val)"
                  :editable="false"

              />
            </b-col>

            <b-col
              cols="12"
              xl="4"
              class="order-sm-3 order-md-2"
            >
              <request-media-dropzone
                ref="requestMediaDropzone"
                :data="pointData"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

  </b-overlay>

</template>

<script>
import { ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BForm, BOverlay
} from 'bootstrap-vue'

import store from '@/store'
import router from '@/router'
import { showToast } from '@/mixins/notification/toasts'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
// import RequestTimeGaps from './RequestTimeGaps.vue'
import RequestOverview from './RequestOverview.vue'
import RequestPostActions from './RequestPostActions.vue'
import RequestMediaDropzone from './RequestMediaDropzone.vue'
import SetLocation from '@/views/apps/location/SetLocation.vue'
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,

    RequestOverview,
    ValidationObserver,
    SetLocation,

    // RequestTimeGaps,
    RequestPostActions,
    RequestMediaDropzone,
  },
  mixins: [showToast],

  data() {
    return {
      savedData: {},
      elementKey: 0,
      pointData: {},
      location: {},
      savedPointData: {},
      error404: false,
      restricted: false,
      loading: false,
      submitting: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'apps-service-request-edit'
          && to.params.id
          && Number.isInteger(parseInt(to.params.id, 10))) {
        this.updatePointData(to.params.id)
      }
      if (to.name === 'apps-service-request-add') {
        this.initPoint()
      }
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.pointData = JSON.parse(this.initPointData)
  },
  computed: {
    offerViewport() {
      if (this.pointData.point_type_id && this.user) {
        const offer = this.user.service_offers.find(offer => offer.point_type.id === this.pointData.point_type_id)
        if (offer && offer.id) {
          return [
            { lat: offer.viewport_north_lat, lng: offer.viewport_east_lng },
            { lat: offer.viewport_south_lat, lng: offer.viewport_west_lng },
          ]
        }
      }
      return []
    },

  },
  mounted() {
    if (router.currentRoute.name === 'apps-service-request-edit'
        && router.currentRoute.params.id
        && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
      this.updatePointData(router.currentRoute.params.id)
    }
  },
  methods: {
    updateLocationEvent(location) {
      this.pointData.address_id = location.id
      this.pointData.lat = location.lat
      this.pointData.lng = location.lng
    },

    updatePointData(pointId) {
      this.loading = true
      store.dispatch('point/fetchServiceRequest', { id: pointId })
        .then(response => {
          if (response.status === 404) {
            this.error404 = true
            this.showAlert404()
          } else {
            const ability = defineAbilityForCurrentUser()
            if (ability.can('update', store.getters['point/Point'])) {
              this.pointData = store.getters['point/point']
              store.commit('location/setLocation', this.pointData.address)
              // this.$refs.requestMediaDropzone.updateUploadedMainImage(this.pointData)
              this.$refs.requestMediaDropzone.updateUploadedFiles(this.pointData)
              this.savedPointData = JSON.stringify(this.pointData)
              this.restricted = false
              this.loading = false
            } else {
              this.restricted = true
              router.push({ name: 'misc-not-authorized' })
            }
          }
        })
    },
    showAlert404() {
      this.$swal({
        title: this.$t('Request Not Found'),
        text: this.$t('Please choose next action:'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Back to requests list'),
        cancelButtonText: this.$t('Request services'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          router.push({ name: 'apps-service-my-requests' })
        } else {
          router.push({ name: 'apps-service-request-add' })
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You didn't save the request"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    initPoint() {
      const initPointData = JSON.parse(this.initPointData)
      store.commit('point/setPoint', initPointData)
      this.pointData = initPointData
      this.savedPointData = JSON.stringify(this.pointData)
      this.elementKey += 1
    },

    requestData() {
      const { pointData } = this

      pointData.address_id = store.state.location.location.id
      pointData.lat = store.state.location.location.lat
      pointData.lng = store.state.location.location.lon

      if (store.state.user.serviceProvider) {
        pointData.receiver_id = store.state.user.serviceProvider.id
      }
      delete pointData.id
      delete pointData.address
      delete pointData.user
      delete pointData.user_id
      delete pointData.point_type
      delete pointData.point_purpose
      return pointData
    },
    // requestAddress() {
    //   return {
    //     country: this.location.address.country,
    //     country_code: this.location.address.country_code,
    //     postcode: this.location.address.postcode,
    //     state: this.location.address.state,
    //     locality: this.location.address.locality,
    //     road: this.location.address.road,
    //     house_number: this.location.address.house_number,
    //     apartment_number: this.location.address.apartment_number,
    //     address_confirmed: this.location.address_confirmed,
    //     viewport: this.location.viewport,
    //   }
    // },
    save() {
      this.submitting = true
      // if (router.currentRoute.name === 'apps-service-request-add') {
      //   const payload = { data: this.requestData()}
      //   store.dispatch('point/addServiceRequest', payload).then(response => {
      //     if ([200, 201, 'success'].includes(response.status)) {
      //       // this.initPoint()
      //       this.savedPointData = JSON.stringify(this.pointData)
      //       router.push({ path: `/service/request/${response.data.data.id}` })
      //     }
      //     this.showToast(response, 'Request')
      //   }).finally(() => {this.submitting = false})
      // }

      if (router.currentRoute.name === 'apps-service-request-edit'
          && router.currentRoute.params.id
          && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
        const payload = { data: this.requestData(), id: router.currentRoute.params.id }
        store.dispatch('point/updateServiceRequest', payload).then(response => {
          this.savedPointData = JSON.stringify(this.pointData)
          router.push({ path: `/service/request/${response.data.data.id}` })
          this.showToast(response, 'Request')
        }).finally(() => {this.submitting = false})
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const currentData = JSON.stringify(this.pointData, (key, val) => {
      if (typeof val === 'function') {
        if (key === 'name') {
          return val() // make it a string, surround it by parenthesis to ensure we can revive it as an anonymous function
        }
      }
      return val
    })
    if (this.savedPointData !== currentData && !this.error404 && !this.restricted) {
      this.showAlert(next)
    } else {
      next()
    }
  },
  setup() {
    // const POINT_APP_STORE_MODULE_NAME = 'point'
    //
    // // Register module
    // if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
    // })
    const pointData = {
      point_type_id: null,
      point_purpose_ids: [],
      name: '',
      description: '',
      lng: 0,
      lat: 0,
      post_status: 'draft',
    }
    const initPointData = JSON.stringify(pointData)
    return {
      pointData,
      initPointData,
    }
  },
}
</script>
